import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Privacy from "../components/Privacy";

const PrivacyPage = () => (
  <Layout>
    <SEO title="FIND Mapping Limited" />
    <Privacy />
  </Layout>
);

export default PrivacyPage;