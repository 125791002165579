import React from 'react';

const Privacy = props => {

  return (
    <section className="privacy">
      <h1>FIND - Cookies & Privacy Policy</h1>
      <p className="privacy-normal">At FIND we are committed to protecting and preserving the privacy of users of our online services and our site. “We” means FIND Mapping Limited and we are the “controller” of personal data that you choose to provide to us, or that we collect from you whilst you use our services or visit our site. This Privacy Policy contains an explanation of what we do with that personal data.</p>
      <p className="privacy-normal">We do occasionally update this Policy so please do return and review this Policy from time to time.</p>
      <p className="privacy-subheading">Information Collected</p>
      <p className="privacy-normal">If you use our online services, we will collect information when you sign up as a user, including your name, email address, telephone number and the details of the business that you work for. We also collect information about your use of our services, such as how many times you have logged in, what data layers you have viewed and the number of prints, exports or reports you have ordered.</p>
      <p className="privacy-normal">If you use our site, we collect and process certain information relating to you and your use of the site. This information includes details of visits to our website and the pages and resources that are accessed, including, but not limited to, traffic data, location data and other communication data that may assist us in understanding how visitors use this website.</p>
      <p className="privacy-normal">If you contact us electronically, either through our site or via other means (such as by telephone, email or social media), we will collect information that you provide to us as a result of contacting us.</p>
      <p className="privacy-subheading">Use of your Information</p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">1. run and manage your account, if you use our online services; <br />2. provide our services and our site to you;<br />3. detect and prevent fraud; <br />4. communicate with you about our services and our site and respond to any queries, issues or other correspondence that you send to us; and <br />5. where you have signed up to receive information and news from us, to send you postal, email or telephone communications about our products and services.</p>
      <p className="privacy-normal">Generally, we process your personal data on the basis that it is in our legitimate interests to do so. Where you use our online services, it is in our legitimate interests to manage your account as this enables us to fulfil our contractual obligations towards the business that you work for. Our other legitimate interests in processing your data include improving our products and services to ensure that we offer the best services; ensuring that correspondence is correctly handled; and promoting our business.</p>
      <p className="privacy-subheading">Disclosing your Information</p>
      <p className="privacy-normal">We use third party service providers to analyse usage of our services and our site and to store data on our behalf. They have access to information about how you use our services and our site and they are obliged to protect your data in accordance with data protection laws. Other than this, we will never pass your personal information to anyone else, except where we are required or permitted to do so by law. We may also use and disclose information in aggregate (so that no individuals are identified) for marketing and strategic development purposes.</p>
      <p className="privacy-subheading">Storing your Personal Data</p>
      <p className="privacy-normal">Data that is provided to us is stored on secure servers within the EU. Details relating to any transactions entered into on our site will be encrypted to ensure their safety. We do not transfer your personal data outside the UK or the EEA ourselves, but sometimes the third party service providers that we engage will do so in order to provide their services. Where this is the case, we ensure that the third party service providers protect your data in accordance with UK data protection laws. We do this either by implementing standard contractual clauses that are recognised as providing equivalent protection to UK laws, or (if the provider processes personal data in the USA) ensuring that the provider is certified with the US Privacy Shield arrangement, which means that the provider complies with provisions broadly equivalent to UK data protection law. We will always take all reasonable precautions to make sure that your data remains secure and is handled in accordance with this Privacy Policy.</p>
      <p className="privacy-normal">The transmission of information via the internet is not completely secure and therefore we cannot guarantee the security of data sent to us electronically and transmission of such data is therefore entirely at your own risk. Where we have given you (or where you have chosen) a password so that you can access certain parts of our site or our services, you are responsible for keeping this password confidential.</p>
      <p className="privacy-normal">If you use our services, we keep your personal data for as long as you have an account with us and up to two years after closing your account. After two years all of your data will be deleted. We only keep information about your use of our services and our site for as long as is needed to analyse your usage and this is then deleted. If you communicate with us, we keep copies of your communications for our own record-keeping and audit purposes and once we consider that they are no longer needed for these purposes, we will delete them. </p>
      <p className="privacy-subheading">Your Rights</p>
      <p className="privacy-normal">Data protection law gives all individuals certain rights over their personal data. You can ask us to: </p>
      <p className="privacy-normal">1. send you a copy of personal data that we hold about you;<br /> 2. correct personal data we hold about you that is inaccurate;<br />3. stop sending you marketing communications; <br />4. delete your data in certain circumstances (we will not always be obliged to do this, if we need your personal data for legitimate reasons set out in this Policy); <br />5. restrict the processing of your personal data in certain circumstances; or <br />6. object to us processing your personal data (again, we may not be required to stop processing your data if we have legitimate grounds to continue). </p>
      <p className="privacy-normal">Please contact us using the details below if you wish to make such a request. </p>
      <p className="privacy-normal">If you are unhappy with how we have processed your personal data, please let us know using the details below. You have the right to complain to the Information Commissioner’s Office in these circumstances and more information can be found at www.ico.org.uk.</p>
      <p className="privacy-subheading">Third Party Links</p>
      <p className="privacy-normal">You might find links to third party websites on our website. These websites should have their own privacy policies which you should check. We do not accept any responsibility or liability for their policies whatsoever as we have no control over them.</p>
      <p className="privacy-subheading">Use of Cookies</p>
      <p className="privacy-normal">A cookie is a simple text file that is stored on your computer or mobile device by a website’s server and only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain some information such as a unique identifier and the site name and some digits and numbers. </p>
      <p className="privacy-normal">Cookies do lots of different jobs, like letting you navigate between pages efficiently, storing your preferences, analysing web traffic and generally improving your experience of a website. Cookies make the interaction between you and the website faster and easier. If a website doesn’t use cookies, it will think you are a new visitor every time you move to a new page on the site – for example, when you enter your login details and move to another page it won’t recognise you and it won’t be able to keep you logged in.</p>
      <p className="privacy-normal">We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not.</p>
      <p className="privacy-normal">Most browsers allow you to turn off the cookie function. If you want to know how to do this please look at the help menu on your browser.</p>
      <p className="privacy-subheading">Contacting Us</p>
      <p className="privacy-normal">If you have any questions or queries relating to this Privacy Policy then please contact us at support@findmaps.co.uk</p>
    </section >
  )
};

export default Privacy;